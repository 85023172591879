import React from 'react';
import {ConnectedRouter} from "connected-react-router";
import {HashRouter as Router} from "react-router-dom";
import history from "../../history";

import AppContainer from './AppContainer';

const RouterContainer= () => {
  
  return (
    <ConnectedRouter history={history}>
      <Router history={history}>
          <AppContainer history={history} />
      </Router>
    </ConnectedRouter>
  );
  
}
export default RouterContainer;