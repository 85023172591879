import React from 'react';
import {connect} from "react-redux";
import {push} from 'connected-react-router';
import {Card, Col, Row} from "antd";
import notFoundImg from '../../resources/img/notFound.svg';

const NotFound = () => {
  return (
    <Card title={'404'} style={{margin: '30px'}}>
      <Row>
        <Col xs={24} md={12}>
          <img src={notFoundImg} alt={''}/>
        </Col>
        <Col xs={24} md={12}>
          <h2><b>Ups...la página que buscas no existe</b></h2>
          <h2>Utiliza el menú ubicado en la parte izquierda para navegar en la aplicación</h2>
        </Col>
      </Row>
      </Card>
  );
} ;

export default connect(null, {push})(NotFound);