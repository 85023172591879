import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect'
import {getLoginPathFromState} from "../Helpers/utils";

const privateRoute = connectedRouterRedirect({
  redirectPath: (state) => getLoginPathFromState(state),
  allowRedirectBack: false,
  authenticatedSelector: (state) => {
   /*  let publicRoutes=[VERIFICATION_ROUTE, HOME_NEWCERTIFICATE_ROUTE];
    let currentRoute=state.router.location.pathname;
    for(let i=0;i<publicRoutes.length;i++)
    {
      if(publicRoutes[i]===currentRoute)
        return true;
    }
    return sessionStorage.logged !== undefined */
    return true;
  },
  wrapperDisplayName: 'PrivateRoute'
});
export default privateRoute;


// Probablemente este código servirá cuando se haga la Migración Moderna Redux
/* import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, children }) => {
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return children;
};

export default PrivateRoute; */
